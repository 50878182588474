// Buttons
.btn {
  display: block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transform: scale(1);
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;

  outline-color: $c-danger;

  &:active {
    transform: scale(0.98);
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    // left: -8rem;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      355.79deg,
      #ffffff 26%,
      rgba(255, 255, 255, 0) 37.41%
    );
    opacity: 0.3;
    transform: rotate(-57deg);
    -webkit-animation: 3.3s linear 0s infinite normal shine;
    animation: 3.3s linear 0s infinite normal shine;
  }
  &.no-shine::after {
    content: '';
    display: none;
  }
  @include easeOut;
  &:hover,
  &:active,
  &:focus {
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  }
}

.btn-primary {
  padding: 1.0625rem;
  background-color: $c-alpha;
  color: $c-light;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.btn-primary--2 {
  padding: 0.8125rem;
}

.btn-primary--3 {
  padding: 0.625rem;
}

.btn-secondary {
  padding: 0.875rem;
  background-color: $c-beta;
  color: $c-light;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  @include easeOut;
  &:hover,
  &:active,
  &:focus {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  }
}

.btn-tertiary {
  padding: 0.75rem;
  border: 1px $c-light solid;
  color: $c-light;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.btn-quaternary {
  padding: 1.0625rem;
  border: 1px $c-alpha solid;
  color: $c-alpha;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  &.hover {
    &:hover,
    &:focus,
    &:active {
      background-color: $c-beta;
      color: $c-light;
      border-color: $c-beta;
    }
  }
}

.btn-quaternary--2 {
  border-color: $c-beta;
  color: $c-beta;
}

.btn-alt {
  // color: #fff;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: left;
  cursor: pointer;
  text-transform: none;
}

@include mediaMd {
  .btn {
    max-width: 15rem;
  }
}
