@include mediaMd {
  .section-numbers {
    & .testimonials {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    & .testimonials__testimonial {
      flex-direction: column;
      flex: 0 0 30%;
      margin-right: 1.5rem;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
