.section-programs {
  text-align: center;
  & .card__info {
    text-align: left;
    padding-left: 7%;
    & p {
      color: $c-muted;
    }
  }
  & .cards-programs {
    & .cards__card {
      display: none;
      position: relative;
      &.show {
        display: block;
      }
      & .card__info {
        color: $c-muted;
        & p {
          display: flex;
          color: $c-muted-3;
          & .align-helper {
            margin-left: 0.6rem;
          }
        }
        & .card-info-icon {
          font-weight: 900;
          color: $c-muted;
        }
      }
      & .info__price{
        padding-top: .5rem;
      }
      & .card__sale{
        position: absolute;
        top: -1rem;
        right: 0;
        display: block;
        text-align: center;
        background-color: $c-danger;
        color: $c-gamma;
        padding: .6rem;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        font-size: 14px;
        line-height: 16px;
      }
      @media only screen and (max-width: 548px) {
        .on-new-line-mobile{
          display: block;
        }
      }
    }
  }
}

.load-more-cards {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@include mediaMd {
  .section-programs {
    text-align: left;
    & .cards-programs {
      & .cards__card {
        flex: initial;
        width: 100%;
        margin-right: 0;
        display: none;
        
        &.show {
          display: grid;
        }
        grid-template-areas:
          'title btn'
          'info btn';
        grid-template-columns: 9fr 3fr;
        grid-gap: 1rem 3rem;

        &:hover,
        &:active,
        &:focus {
          & .card__title {
            color: $c-alpha;
          }
        }

        & .card__title {
          @include easeOut;
          grid-area: title;
        }
        & .h3,
        & .h4 {
          margin: 0;
        }
        & .card__info {
          grid-area: info;
          color: $c-muted;
          padding-left: 0;
          & p {
            color: $c-muted-3;
            & .align-helper {
              margin-left: 0.3rem;
            }
          }

          & .h4 {
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
          }
        }
        & .card__btn {
          margin-top: 0;
          grid-area: btn;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        & .btn {
          margin: 0;
        }
        & .card__sale{
          right: -0.9rem;
        }
      }
    }
  }
}
