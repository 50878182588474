header {
  position: absolute;
  min-width: 100%;
  min-height: 5.625rem;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: $c-alpha;
  z-index: 1;
  padding-top: 80px;
  padding-bottom: 10px;
}

.hamburger {
  width: 3.3125rem;
  // -webkit-appearance: none;
  // border-radius: 0;
}

.hamburger__line {
  width: 100%;
  background-color: set-text-color($c-alpha);
  height: 0.3125rem;
  &:nth-child(1),
  &:nth-child(2) {
    margin-bottom: 0.4375rem;
  }
}

.header-logo {
  width: 100%;
  max-width: 3.5rem;
  height: auto;
}
.logo-group {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 16rem;
}
.logo-group__text {
  margin-left: 1rem;
  color: $c-light;
  font-size: 0.8rem;
}
@include mediaMd {
  body .section-heading {
    padding-top: 0;
  }
  .container--header {
    width: 100%;
  }
  header {
    min-height: 7rem;
    color: $c-light;
    .btn-tertiary {
      @include easeOut;
      &:hover,
      &:active {
        background-color: $c-beta;
        border: 1px solid transparent;
      }
    }
  }

  .desktop-menu__header-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .header-group__number {
    margin-right: 1.5rem;
  }

  .header-logo {
    width: 100%;
    max-width: 4.5rem;
    height: auto;
  }
  .desktop-menu {
    width: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
  .menu-items {
    display: flex;
    margin: 0 0 1rem 2rem;
    padding: 0;
  }
  .menu-items__item {
    display: flex;
    margin-right: 1rem;
    border-bottom: 1px solid transparent;
    &:nth-last-child(1) {
      margin-right: 0;
    }
    & a {
      @include easeOut;
      outline-color: $c-danger;
      color: $c-light;
      border-bottom: 1px solid transparent;
      &:hover,
      &:active {
        border-bottom: 1px solid $c-light;
      }
    }
  }
  .headings {
    & h1 {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
}

// Desktops & Laptops
@include mediaXl {
  .section-heading__content h1 {
    font-size: 2rem;
  }
  .headings {
    & h1 {
      font-size: 3rem;
      line-height: 2.9rem;
    }
  }
}

.top-banner{
  position: absolute;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  top: 0;
  left: 0;
  background-color: #07243c;
  background-image: url('../img/banner-person.png'), url('../img/top-bg.jpg');
  background-repeat: no-repeat, no-repeat;
  background-size: contain, cover;
  background-position: center, center;
  height: 67px;
  width: 100%;
  cursor: pointer;
  & .container{
    position: relative;
    padding: 0;
  }
  & .top-banner__waves{
    position: absolute;
  }
  & .top-banner__waves--orange{
    display: flex;
    flex-direction: column;
    left: 0;
    top: .8rem;
  }
  & .top-banner__waves--light{
    right: -3.3rem;
    top: -.4rem;
  }
  & .top-banner__text-left{
    position: absolute;
    top: 0.5rem;
    left: 14%;
  }
  & .top-banner__text-right{
    position: absolute;
    top: 0.5rem;
    right: 5%;
  }
  & .text__sale{
    margin: 0;
    font-size: 1.4rem;
    color: $c-beta;
    font-weight: 700;
  }
  & .text__info{
    margin: 0;
    font-size: 0.8rem;
    color: $c-light;
  }
}

@media only screen and (max-width: 1037px) {
  .top-banner{
    & .top-banner__waves--orange{
      left: 1rem;
    }
  }
}

@media only screen and (max-width: 877px) {
  .top-banner{
    background-image: url('../img/top-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    & .top-banner__waves--orange{
      left: 1rem;
    }
    & .top-banner__text-left{
      left: 20%;
    }
    & .top-banner__text-right{
      right: 13%;
    }
  }
}

@media only screen and (max-width: 797px) {
  .top-banner{
    background-image: url('../img/top-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    & .container{
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    & .top-banner__waves--orange{
      display: none;
    }
    & .top-banner__waves--light{
      display: none;
    }
    & .top-banner__text-left{
      position: relative;
      left: initial;
      top: initial;
      margin-right: 1rem;
    }
    & .top-banner__text-right{
      position: relative;
      right: initial;
      top: initial;
    }
  }
}

@media only screen and (max-width: 797px) {
  .top-banner{
    background-image: url('../img/top-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    & .container{
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    & .top-banner__waves--orange{
      display: none;
    }
    & .top-banner__waves--light{
      display: none;
    }
    & .top-banner__text-left{
      position: relative;
      left: initial;
      top: initial;
      margin-right: 1rem;
    }
    & .top-banner__text-right{
      position: relative;
      right: initial;
      top: initial;
    }
  }
}

@media only screen and (max-width: 491px) {
  header{
    padding-top: 170px;
  }
  @media only screen and (max-height: 752px) {
    .section-heading{
      padding-top: 7rem;
    }
  }
  @media only screen and (max-height: 594px) {
    .section-heading{
      padding-top: 10rem;
    }
  }
  @media only screen and (max-height: 482px) {
    .section-heading{
      padding-top: 13rem;
    }
  }
  .top-banner{
    height: 10rem;
    & .container{
      justify-content: center;
      flex-direction: column;
    }
    & .top-banner__text-left{
      margin-bottom: 1rem;
      text-align: center;
    }
    & .top-banner__text-right{
      text-align: center;
    }
  }
}

// @media only screen and (max-width: 1215px) {
//   header{
//     padding-top: 0;
//   }
//   .top-banner{
//     display: none;
//   }
// }