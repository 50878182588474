// Utility Classes

// .noselect {
//   -webkit-touch-callout: none; /* iOS Safari */
//   -webkit-user-select: none; /* Safari */
//   -khtml-user-select: none; /* Konqueror HTML */
//   -moz-user-select: none; /* Old versions of Firefox */
//   -ms-user-select: none; /* Internet Explorer/Edge */
//   user-select: none; /* Non-prefixed version, currently
//                                   supported by Chrome, Edge, Opera and Firefox */
// }

.container {
  min-width: 100%;
  min-height: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.container--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-beta {
  color: $c-beta;
}

.c-alpha {
  color: $c-alpha;
}

.c-muted {
  color: $c-muted;
}

.c-muted-3 {
  color: $c-muted-3;
}

.c-danger{
  color: $c-danger;
}

.d-block {
  display: block;
}

.fw-bold {
  font-weight: bold;
}

.text-crossed{
  text-decoration: line-through;
  text-decoration-color: $c-danger;
}

@include mediaMd {
  .container {
    min-width: initial;
    max-width: 1024px;
    margin: 0 auto;
  }
}
