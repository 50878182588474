.section-btw {
  background-color: $c-alpha;
  color: set-text-color($c-alpha);
  overflow: hidden;
}

@include mediaMd {
  .section-btw {
    & .cards {
      & .cards__card {
        margin: 0;
        padding: 0;
      }
    }
  }
}
