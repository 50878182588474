.testimonials__testimonial {
  text-align: center;
  margin-bottom: 2rem;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.testimonial__number {
  display: flex;
  justify-content: center;
  align-items: center;
  & p {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
  }
}

.testimonial__info {
  & p {
    margin-top: 0.8rem;
  }
}

@include mediaMd {
  .testimonials__testimonial {
    text-align: left;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .testimonial__number {
    min-width: 8rem;
    margin-right: 1rem;
  }
}
