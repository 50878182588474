@-webkit-keyframes shine {
  from {
    left: -600%;
  }

  to {
    left: 700%;
  }
}

@keyframes shine {
  from {
    left: -600%;
  }

  to {
    left: 700%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
