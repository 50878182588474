@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import "normalize";
@import "config";
@import "animations";
@import "utility";
@import "general";
@import "menu";
@import "header";
@import "list-items";
@import "buttons";
@import "cards";
@import "testimonials";
@import "forms";
@import "carousel";
@import "section-heading";
@import "section-below-heading";
@import "section-about";
@import "section-numbers";
@import "section-trust";
@import "section-programs";
@import "section-form";
@import "section-btw";
@import "section-get-diploma";
@import "section-reasons";
@import "section-order-now";
@import "section-our-clietns";
@import "steps";
@import "footer";
@import "module";

@import "media";
