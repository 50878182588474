.section-heading {
  min-height: 100vh;
  background-color: $c-alpha;
  color: set-text-color($c-alpha);
  background-image: url('../img/bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(
      180deg,
      rgba(40, 71, 115, 0.88) 37.21%,
      #284773 99.21%
    );
  }

  & .form {
    background-color: $c-light;
    color: $c-dark;
  }

  // Dynamic BG
  &.management{
    background-image: url('../img/bgs/Менеджмент_640_360.jpg');
    background-position: right;
  }
  @include mediaLg{
    &.management{
      background-image: url('../img/bgs/Менеджмент_1366_768.jpg');
    }
  }
  @include mediaXl{
    &.management{
      background-image: url('../img/bgs/Менеджмент_1920_1080.jpg');
    }
  }

  &.economics{
    background-image: url('../img/bgs/Экономика_640_360_.jpg');
  }
  @include mediaLg{
    &.economics{
      background-image: url('../img/bgs/Экономика_1366_768_.jpg');
    }
  }
  @include mediaXl{
    &.economics{
      background-image: url('../img/bgs/Экономика_1920_1080.jpg');
    }
  }

  &.pedagogy{
    background-image: url('../img/bgs/Педагогика_640_360_.jpg');
    background-position: center;
  }
  @include mediaLg{
    &.pedagogy{
      background-image: url('../img/bgs/Педагогика_1366_768_.jpg');
      background-position: right;
    }
  }
  @include mediaXl{
    &.pedagogy{
      background-image: url('../img/bgs/Педагогика_1920_1080.jpg');
      background-position: right;
    }
  }

  &.psychology{
    background-image: url('../img/bgs/Психология_640_360_.jpg');
    background-position: center;
  }
  @include mediaLg{
    &.psychology{
      background-image: url('../img/bgs/Психология_1366_768_.jpg');
      background-position: right;
    }
  }
  @include mediaXl{
    &.psychology{
      background-image: url('../img/bgs/Психология_1920_1080.jpg');
      background-position: right;
    }
  }

  &.healthcare{
    background-image: url('../img/bgs/Медицина_640_360.jpg');
    background-position: right;
  }
  @include mediaLg{
    &.healthcare{
      background-image: url('../img/bgs/Медицина_1366_768.jpg');
      background-position: right;
    }
  }
  @include mediaXl{
    &.healthcare{
      background-image: url('../img/bgs/Медицина_1920_1080.jpg');
      background-position: right;
    }
  }

  &.informatics{
    background-image: url('../img/bgs/Информатика и вычислительная техника_640_360.jpg');
    background-position: left;
  }
  @include mediaLg{
    &.informatics{
      background-image: url('../img/bgs/Информатика и вычислительная техника_1366_768.jpg');
    }
  }
  @include mediaXl{
    &.informatics{
      background-image: url('../img/bgs/Информатика и вычислительная техника_1920_1080.jpg');
    }
  }

  &.jurisprudence{
    background-image: url('../img/bgs/Юриспруденция_640_360.jpg');
    background-position: right;
  }
  @include mediaLg{
    &.jurisprudence{
      background-image: url('../img/bgs/Юриспруденция_1366_768.jpg');
    }
  }
  @include mediaXl{
    &.jurisprudence{
      background-image: url('../img/bgs/Юриспруденция_1920_1080.jpg');
    }
  }

  &.marketing{
    background-image: url('../img/bgs/Маркетинг_640_360.jpg');
    background-position: right;
  }
  @include mediaLg{
    &.marketing{
      background-image: url('../img/bgs/Маркетинг_1366_768.jpg');
    }
  }
  @include mediaXl{
    &.marketing{
      background-image: url('../img/bgs/Маркетинг_1920_1080.jpg');
    }
  }

  &.technology{
    background-image: url('../img/bgs/Технические направлени_640_360.jpg');
  }
  @include mediaLg{
    &.technology{
      background-image: url('../img/bgs/Технические направлени_1366_768.jpg');
    }
  }
  @include mediaXl{
    &.technology{
      background-image: url('../img/bgs/Технические направлени_1920_1080.jpg');
    }
  }
}

.section-heading__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  & h1 {
    text-align: center;
  }
}

@include mediaMd {
  @media only screen and (max-height: 1100px) {
    .section-heading__content {
      margin-top: 4%;
    }
  }

  @media only screen and (max-height: 1028px) {
    .section-heading__content {
      margin-top: 6%;
      margin-bottom: 3%;
    }
  }

  @media only screen and (max-height: 975px) {
    .section-heading__content {
      margin-top: 8%;
      margin-bottom: 5%;
    }
  }
  @media only screen and (max-height: 929px) {
    .section-heading__content {
      margin-top: 10%;
      margin-bottom: 6%;
    }
  }
  @media only screen and (max-height: 897px) {
    .section-heading__content {
      margin-bottom: 7%;
    }
  }
  @media only screen and (max-height: 868px) {
    .section-heading__content {
      margin-top: 14%;
      margin-bottom: 17%;
    }
    main .section-below-heading {
      // top: 90vh;
    }
  }
  @media only screen and (max-height: 768px) {
    .section-heading__content {
      margin-top: 18%;
      margin-bottom: 17%;
    }
    main .section-below-heading {
      // top: 90vh;
    }
  }
  @media only screen and (max-height: 689px) {
    .section-heading__content {
      margin-top: 23%;
      margin-bottom: 20%;
    }
    main .section-below-heading {
      // top: 96vh;
    }
  }
  .section-heading {
    &.section {
      padding-bottom: 0;
    }
  }
  .section-heading__content {
    flex-direction: row;
  }

  .section-heading__content {
    & h1 {
      text-align: left;
    }
  }
}
