.module {
  display: none;
  &.show {
    @include easeOut;
    display: block;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    z-index: 10;
    & .form {
      background-color: $c-light;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
    }
    animation-name: fadeIn;
    animation-duration: 0.3s;
  }
}

@include mediaMd {
  .module {
    display: none;
    &.show {
      @include easeOut;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      min-height: 100vh;
      width: 100%;
      z-index: 10;
      & .form {
        overflow: auto;
        background-color: $c-light;
        position: relative;
        max-height: 100vh;
        border-radius: 15px;
        & .btn {
          margin-bottom: 2rem;
        }
      }
      animation-name: fadeIn;
      animation-duration: 0.3s;
    }
  }
}

@media only screen and (max-height: 700px) {
  .module{
    .form__title{
      margin-bottom: 1rem;
    }
    .form__subtitle{
      display: none;
    }
  }
}