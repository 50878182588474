// Area outside of menu
.area-outside-of-menu {
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 20%;
  height: 100%;
  z-index: 100;
  visibility: hidden;
}

// .area-at-the-right-edge {
//   background: transparent;
//   position: fixed;
//   top: 10;
//   right: 0;
//   width: 10%;
//   height: 78%;
//   z-index: -10;
//   visibility: visible;
// }

// Menu Button
.header-wrapper__menu {
  z-index: 99;
}

.menu__menu-mobile {
  z-index: 99;
  @include easeOut;
}

.menu-mobile__toggler {
  display: block;
  position: absolute;
  width: 3.3125rem;
  height: 1.8125rem;
  margin: 0;
  padding: 0;
  opacity: 0;
  z-index: 90;
  -webkit-appearance: none;
  border-radius: 0;

  &:checked {
    position: fixed;
  }

  &:checked ~ .menu-mobile__menu-panel {
    transform: translateX(0);
  }

  &:checked ~ .area-outside-of-menu {
    visibility: visible;
  }

  &:checked ~ .area-at-the-right-edge {
    visibility: hidden;
  }
}

.menu-mobile__hamburder {
  cursor: pointer;
  z-index: 93;
}

.menu-mobile__hamburder--panel {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.8rem;
  margin-right: 1.5rem;

  & .menu-btn__btn-line {
    background-color: #fff;
  }

  &::before {
    content: '\00d7';
    display: block;
    margin: 0 auto;
    padding-right: 0.4rem;
    padding-top: 0.4rem;
    font-weight: 300;
    font-family: Arial, sans-serif;
    font-size: 4rem;
  }
}

.menu-btn__btn-line {
  width: 3.3125rem;
  height: 0.3125rem;
  margin-bottom: 0.4375rem;
  border-radius: 0.15625rem;
  z-index: 95;
  // background: $secondary-color;
}

.menu-mobile__menu-panel {
  position: fixed;
  background-color: $c-alpha;
  color: #fff;
  top: 0;
  right: 0;
  transform: translateX(102%);
  width: 80%;
  // max-width: 19.875rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-shadow: 0 0 0.625rem 0.625rem rgba(0, 0, 0, 0.09);
  z-index: 0;
  @include easeOut;
}

.number-wrapper__text {
  font-family: Montserrat, Roboto, sans-serif;
  font-size: 0.875rem;
  line-height: 1.0625rem;
}

.number-wrapper__number {
  margin-top: 0.5rem;
  font-size: 1.75rem;
  line-height: 2.125rem;

  // white-space:nowrap;
  // overflow-x: auto;
  & a {
    color: #fff;
  }
}

.number-wrapper__btn {
  margin-top: 1rem;
  font-size: 1.1875rem;
  line-height: 1.375rem;

  & a {
    color: #fff;
  }
}

.side-panel-nav {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-top: 3.3125rem;
}

.side-panel-nav-items__nav-item {
  margin-bottom: 2.25rem;
}

.nav-item__link {
  position: relative;
  color: #fff;
}

@include mediaMd {
  .mobile-menu {
    display: none;
  }
}

.side-panel-nav__side-panel-nav-items {
  display: flex;
  flex-direction: column;
  padding: 0;
  & .side-panel-nav-items__nav-item {
    display: flex;
  }
}
