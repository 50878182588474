.section-below-heading {
  background-color: $c-alpha;
  color: set-text-color($c-alpha);
  padding-top: 0;
  & .cards {
    & .cards__card {
      & .card__icon {
        & img {
          width: 3.4rem;
        }
      }
    }
  }
}

@include mediaMd {
  .section-below-heading {
    // position: absolute;
    // width: 100%;
    // top: 80vh;
    // padding-bottom: 0;
    padding-top: 2.5rem;
    margin-top: -10rem;
    & .cards {
      flex-direction: row;
      justify-content: space-between;
      & .cards__card {
        max-width: 31%;
        margin-right: 1rem;
        margin-bottom: 0;
        &:nth-last-child(1) {
          margin-right: 0;
        }
        & p {
          font-size: 1rem;
        }
      }
    }
  }
  // @media only screen and (max-height: 923px) {
  //   .section-heading__content {
  //   }
  // }
  // @media only screen and (max-height: 860px) {
  //   .section-heading__content {
  //     margin-top: 10%;
  //   }
  //   main .section-below-heading {
  //     top: 90vh;
  //   }
  // }
  // @media only screen and (max-height: 689px) {
  //   .section-heading__content {
  //     margin-top: 14%;
  //   }
  //   main .section-below-heading {
  //     top: 96vh;
  //   }
  // }
}
