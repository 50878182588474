.section-reasons {
  & .cards {
    & .cards__card {
      & .card__title {
        color: $c-alpha;
      }
      & .card__icon {
        margin-right: 0.5rem;
      }
      &:nth-child(1) {
        & .card__icon {
          background-image: url(../img/svg/book-black.svg);
        }
      }
      &:nth-child(2) {
        & .card__icon {
          background-image: url(../img/svg/shield-check.svg);
        }
      }
      &:nth-child(3) {
        & .card__icon {
          background-image: url(../img/svg/heart.svg);
        }
      }
      &:nth-child(4) {
        & .card__icon {
          background-image: url(../img/svg/calendar.svg);
        }
      }
      &:nth-child(5) {
        & .card__icon {
          background-image: url(../img/svg/person-square.svg);
        }
      }
      &:nth-child(6) {
        & .card__icon {
          background-image: url(../img/svg/chat-text.svg);
        }
      }
    }
  }
}
