footer {
  background-color: $c-alpha;
  & .content p {
    text-align: center;
  }
  & p,
  & .title {
    color: #fff;
  }
  padding-top: 2rem;
  form {
    background-color: #fff;
  }
}

.footer-under {
  margin-top: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $c-dark-2;
  color: $c-muted;

  & p {
    color: $c-muted;
  }
  & .list-items {
    padding-top: 1rem;
  }
  & .btn-alt {
    color: $c-muted;
  }
}

@include mediaMd {
  footer {
    & .content p {
      text-align: left;
    }
    & .contact-info {
      margin-right: 3rem;
    }
    & .content {
      display: flex;
      flex: 0 49%;
      justify-content: space-between;
      align-items: center;
    }

    & .list-items {
      flex-wrap: wrap;
      max-height: 250px;
      & .list-items__list-item {
        margin-right: 1rem;
        max-width: 15rem;
        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }
}
