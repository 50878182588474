.carousel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.carousel__item {
  display: flex;
  flex-wrap: wrap;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex: 0 48%;
  // min-width: 7rem;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  & img {
    margin: 0 auto;
    max-width: 7rem;
  }
  &:nth-child(even) {
    padding-right: 0;
    margin-right: 0;
  }
  &:nth-child(odd) {
    padding-left: 0;
    margin-right: 0;
  }
}

@include mediaMd {
  .carousel__item {
    flex: 0 22%;
  }
}
