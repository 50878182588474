@include mediaMd {
  .section-form {
    & .container {
      // display: flex;
      // justify-content: center;
      // align-items: center;
    }
    & .title {
      margin-right: 1.5rem;
    }
  }
}
