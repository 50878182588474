html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  background-color: $c-light;
  color: $c-dark;
  font-size: 16px;
  min-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: auto;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: $c-dark;
}

body {
  min-height: 100%;
  max-width: 100%;
  scroll-behavior: auto;
  overflow-x: hidden;
}

body.dark-overlay {
  &:after {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 100%;
    z-index: 97;
    background: rgba(0, 0, 0, 0.4);
  }
}

main {
  min-height: 100%;
}

.section {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  position: relative;
}

.content {
  position: relative;
}

.title {
  text-align: center;
  margin-bottom: 2.5rem;
  & h2 {
    margin-top: 0;
  }
}

.top-right-corner {
  position: absolute;
  width: 60px;
  height: 60px;
  background: linear-gradient(44.84deg, #368be3 50.1%, $c-light 50.33%);
  box-shadow: -2px 2px 16px rgba(0, 0, 0, 0.25);
  top: 0;
  right: 0;
}

.desktop-only {
  display: none;
}

@include mediaMd {
  html {
    font-size: 18px;
  }
  .title {
    text-align: left;
  }
  .desktop-only {
    display: block;
  }
  span.desktop-only {
    display: inline;
  }
  .mobile-only,
  .btn.mobile-only {
    display: none;
  }
}

// Desktops & Laptops
@include mediaXl {
  html {
    font-size: 20px;
  }
}
