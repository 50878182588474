.cards {
  & .cards__card {
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    padding: 1.5rem;
    border-radius: 0.6rem;
    background-color: $c-light;
    margin-bottom: 2rem;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    & .card__icon {
      background-color: $c-light;
      // background-image: url('../img/icons/card_1.png');
      background-position: left;
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 100px;
      width: 100%;
    }
    & .card__title p {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    & .card__btn {
      margin-top: 2rem;
    }
  }
  .card-icon{
    padding-top: 0.52rem;
  }
}

.cards-alt {
  & .cards__card {
    & .card__icon {
      grid-area: cardIcon;
      min-width: 3.75rem;
      max-width: 3.75rem;
      background-position: initial;
      background-color: transparent;
    }
    & .card__title {
      grid-area: cardTitle;
      & p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    & .card__info {
      grid-area: cardInfo;
      min-width: 100%;
      & p {
        margin: 0;
      }
    }
    display: grid;
    grid-gap: 1rem;
    grid-template-areas:
      'cardIcon cardTitle'
      'cardIcon cardInfo';
    grid-template-columns: 1fr 11fr;
    padding: 0;
  }
}

.cards-alt-2 {
  display: flex;
  flex-direction: column;
  & .cards__card {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    padding: 0;
    & .card__icon {
      max-width: 3.75rem;
      min-height: 3.75rem;
      margin-right: 1rem;
      background-position: initial;
    }
    & .card__title {
      & p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    & .card__info {
      & p {
        margin: 0;
      }
    }
  }
}

.cards-alt-3 {
  & .cards__card {
    padding: 0 0 0 1rem;
  }
}

.cards--transparent {
  & .cards__card {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    & .card__icon {
      background-color: transparent;
    }
  }
}

@include mediaMd {
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    & .cards__card {
      &:nth-child(odd) {
        margin-right: 4%;
      }
      &:nth-last-child(1) {
        margin-bottom: 2rem;
      }
      flex: 0 48%;
    }
  }
}

@include mediaXl {
}
